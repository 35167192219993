/* This file requires site.js to work */


/*
var Site = Site || {};




(function($) {

    Site.Video = {

        players: null,


        onLoad: function() {

            if (!$('video')[0]) return;

            Site.Video.players = [];

            for (var i = $('video').length - 1; i >= 0; i--) {
                var player = new Video($('video').eq(i));
                Site.Video.players.push(player);
            }

        },


        onUnLoad: function() {
            if (Site.Video.players && Site.Video.players.length)
                for (var i = Site.Video.players.length - 1; i >= 0; i--)
                    Site.Video.players[i].destroy();
        }

    };



}(jQuery));
*/
