/*var Site = Site || {},
    $window = $window || $(window),
    $body = $body || $("body");


(function($) {


    Site.Fullscreen = {


        el: '.fullscreen',
        $el: null,

        item: '.fullscreen__item',
        $items: null,

        counter: '.fullscreen__counter',
        $counter: null,

        caption: '.fullscreen__caption',
        $caption: null,

        $youtubes: null,

        $current: null,



        onLoad: function() {

            if (!$(Site.Fullscreen.el)[0]) return;
            Site.Fullscreen.$el = $(Site.Fullscreen.el);
            Site.Fullscreen.$items = $(Site.Fullscreen.item);
            Site.Fullscreen.$counter = $(Site.Fullscreen.counter);
            Site.Fullscreen.$caption = $(Site.Fullscreen.caption);
            $body.addClass('has-fullscreen');
            Site.Fullscreen.bind();
            Site.Fullscreen.load();
            Site.Fullscreen.onResize();
            Site.Fullscreen.initYouTubeAPI();

        },



        onUnLoad: function() {

            if (!Site.Fullscreen.$el) return;
            Site.Fullscreen.unbind();
            Site.Fullscreen.$el = null;
            Site.Fullscreen.$current = null;
            $body.removeClass('has-fullscreen');
            Site.Search.onResize(true);

        },



        bind: function() {

            $('.fullscreen__arrow--left').off('.full').on('click.full', Site.Fullscreen.onPrevClick);
            $('.fullscreen__arrow--right').off('.full').on('click.full', Site.Fullscreen.onNextClick);
            $('.fullscreen__item .image').off('.full').on('click.full', function() {
                Site.Fullscreen.change(1);
            }).css('cursor', 'pointer');



            $('.fullscreen__info').off('.full').on('click.full', Site.Fullscreen.onInfoClick);

            // load video
            if ($('.video-player.is-custom')[0])
                $('.video-player.is-custom video').each(function(index, el) {
                    el.addEventListener('loadedmetadata', function() {
                        if (!el) return;
                        $(el)
                            .parents('.video-player').data({
                                width: el.videoWidth,
                                height: el.videoHeight,
                                ratio: el.videoWidth / el.videoHeight
                            });
                        Site.Fullscreen.onResize();
                        $(el).removeClass('is-loading');
                    });
                });

            $(document).off('.fullscreen').on('keydown.fullscreen', function(e) {
                var key = e.keyCode || e.which;

                switch (key) {

                    case 37: // Left
                        Site.Fullscreen.change(-1);
                        return;

                    case 39: // Right
                        Site.Fullscreen.change(+1);
                        return false;

                    case 27: // Esc
                        $('.fullscreen__close').trigger('click');
                        return false;
                }
            });



            $('.fullscreen__close').off('.fullscreen__close').on('click.fullscreen__close', function(e) {

                e.preventDefault();
console.log('---------click.fullscreen__close------------');
                Site.PushStates.back(e.currentTarget.href);

                return false;

            });

        },





        unbind: function() {

            $(document).off('.fullscreen');

        },



        // shows chosen image
        // based on current window.location.pathname
        load: function() {
            console.log(' __________________Fullscreen load');
  console.log( window.location.pathname + window.location.search);

            var el = $('[data-full="' + window.location.pathname + window.location.search + '"]')[0] || Site.Fullscreen.$items.first()[0];
            if (!el) return;

            console.log(el);
            Site.Fullscreen.hideOld($(el));

        },



        // passs element needs to be opened
        // after closing current item:
        hideOld: function($el) {

            if (!Site.Fullscreen.$current)
                Site.Fullscreen.showNew($el);

            else {

                // pause video:
                if (Site.Fullscreen.$current.find('.video-player')[0] && Site.Fullscreen.$current.find('.video-player video').data('video'))
                    Site.Fullscreen.$current.find('.video-player video').data('video').pause();

                // fade out image:
                TweenMax.killTweensOf(Site.Fullscreen.$items);
                TweenMax.to(Site.Fullscreen.$current, 0.3, {
                    opacity: 0,
                    ease: Power2.easeOut,
                    onComplete: function() {
                        Site.Fullscreen.$current.hide().removeClass('is-current');
                        Site.Fullscreen.$current = null;
                        Site.Fullscreen.showNew($el);
                    }
                });

                // fade out icons:
                Site.Fullscreen.$caption.fadeOut(100);
            }
        },



        showNew: function($el) {

            Site.Fullscreen.$current = $el;
            Site.Fullscreen.$current.show().addClass('is-current')
                .siblings().removeClass('is-current');

            // fade in chosen item:
            TweenMax.killTweensOf(Site.Fullscreen.$items);
            TweenMax.fromTo(Site.Fullscreen.$current, 0.5, {
                opacity: 0
            }, {
                opacity: 1,
                ease: Power2.easeOut
            });

            // update counter number and icons:
            Site.Fullscreen.$counter.html(($el.index() + 1) + ' / ' + Site.Fullscreen.$items.length);
            Site.Fullscreen.$caption.fadeIn(300).html($el.find('figcaption').html() || '');

            $info = Site.Fullscreen.$current.find('.slide-description');
            if(!$info.length) {
                Site.Fullscreen.$el.removeClass('has-info');
                $('.fullscreen__info').addClass('is-hidden');
            } else {
                $('.fullscreen__info').removeClass('is-hidden');
            }

        },



        onPrevClick: function(e) {
            e.preventDefault();
            Site.Fullscreen.change(-1);
            return false;
        },



        onNextClick: function(e) {
            e.preventDefault();
            Site.Fullscreen.change(1);
            return false;
        },




        change: function(dir) {

            var chosen;

            //console.log( Site.Fullscreen.$items.filter('.is-current').html());
            if(Site.Fullscreen.$items.filter('.is-current').children('figure').hasClass('is-youtube')){
                //console.log('----------------------------------ostatni był youtube');
                Site.Fullscreen.$items.filter('.is-current').children('figure').find('iframe.youtube').data('youtube').pauseVideo();
            }


            if (dir < 1)
                chosen = Site.Fullscreen.$items.filter('.is-current').prev()[0] || Site.Fullscreen.$items.last()[0];
            else
                chosen = Site.Fullscreen.$items.filter('.is-current').next()[0] || Site.Fullscreen.$items.first()[0];


            Site.PushStates.goTo($(chosen).attr('data-full'), true);
        },




        onResize: function() {

            if (!Site.Fullscreen.$el) return;

            var $current = Site.Fullscreen.$current;



            if($(window).width() >= 1024){
                Site.Fullscreen.$el.addClass('has-info');
            }else{
                Site.Fullscreen.$el.removeClass('has-info');
            }





            if (!Site.Fullscreen.$current) return;   
            
            var maxWidth = $current.width(),
                maxHeight = $current.height(),
                videoRatio = $current.data('ratio'),
                ratio = parseInt(videoRatio, 10) || 16 / 9,
                wdt = maxWidth / maxHeight < ratio ? maxWidth : maxHeight * ratio,
                hgt = maxWidth / maxHeight < ratio ? maxWidth / ratio : maxHeight,
                mL = (maxWidth - wdt) * 0.5,
                mT = (maxHeight - hgt) * 0.5,
                headerH = $('.fullscreen__header').height();

            $('.video-player', Site.Fullscreen.$el).css({
                width: wdt,
                height: hgt,
                marginLeft: mL,
                marginTop: mT
            });

            $('.fullscreen__content').css({'top': headerH});

            //powyzej 100px przelaczamy na widok blokowy dla ikonek
            Site.Fullscreen.$el.toggleClass('big-header', headerH > 90);
    
        },



        initYouTubeAPI: function() {
            //console.log('----------1------------initYouTubeAPI');
            Site.Fullscreen.$youtubes = $('iframe.youtube');
            if (Site.Fullscreen.$youtubes) {
                if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
                    window.onYouTubePlayerAPIReady = $.proxy(function() {
                        Site.Fullscreen.loadYTPlayers();
                    }, this);
                    $.getScript('//www.youtube.com/player_api');
                } else {
                    Site.Fullscreen.loadYTPlayers();
                }
            }
        },

        loadYTPlayers: function() {
            //console.log('----------2------------loadYTPlayers');
            var youtubes = [];
            Site.Fullscreen.$youtubes.each($.proxy(function(i, iframe) {
                var id = $(iframe).attr('id');
                //console.log('----------2--------id: '+id );
                var player = new YT.Player(id, {
                    events: {
                        'onReady': this.onPlayerReady(iframe),
                        'onStateChange': this.onPlayerStateChange(iframe)
                    }
                });
                youtubes[id] = player;
                //$(iframe).parents('.video-player').addClass('loaded');
            }, this));
        },

        onPlayerReady: function(iframe) {
            //console.log('----------3------------nPlayerReady');
            return function(event) {
                // var player = event.target;
                // $(iframe).siblings('.play').on('click', function() {
                //     player.playVideo();
                // });
                $(iframe).data('youtube', event.target);
            };
        },

        onPlayerStateChange: function(iframe) {
             //console.log('----------4------------onPlayerStateChange');
             return function(event) {
                 var states = ['ended', 'playing', 'paused', 'buffering', 'cued'],
                     poster = $(iframe).siblings('img')[0] ? ' with-poster' : '';

            //        var title = $(iframe).attr('data-ga');
            //     // set iframe class
            //     if ($(iframe).hasClass('unstarted')) {
            //         ga('send', 'event', 'tile', 'play', title);
            //     } else if (event.data === 0) {
            //         ga('send', 'event', 'tile', 'complete', title);
            //     }
                 var state = event.data == -1 ? 'stopped' : states[event.data];
                 $(iframe).removeClass().addClass('youtube ' + state + poster);


                // pause other videos
                // if (state == 'playing' && $(iframe).parents('#popup').find('iframe.youtube')[0]) {
                //     $(iframe).parents('#popup').find('iframe.youtube').each(function() {
                //         if (!$(this).is($(iframe)))
                //             $(this).data('youtube').pauseVideo();
                //     });
                // }
            };
        },

        onInfoClick: function(e) {
            e.preventDefault();
            Site.Fullscreen.$el.toggleClass('has-info');

        }





    };



}(jQuery));

*/
