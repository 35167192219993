var Site = Site || {},
    $window = $window || jQuery(window),
    $body = $body || jQuery("body"),
    console = console || {
        log: function() {
            return true;
        }
    };


(function($) {

    var SiteMethods = {

        st: 0,
        lastSt: -1,
        scrollY: 0,
        initialized: false,
        isShowing: false,
        sectionIDLast: 0,
        sectionID: 0,
        graph1: {},
        requestId: false,
        typeNavigation: '',
        stats: '',
        isReady: false,
        breakpointLast: '',

        requestIdDraw1: false,
        requestIdDraw2: false,

        requestIdDrawSnowCar1: false,
        requestIdDrawSnowCar2: false,

        menuAnimating: false,


        callAll: function(f, data) {
            var o,
                que = [];
            for (o in Site) {
                if (typeof(Site[o]) === "object" && Site[o] !== null) {
                    if (f in Site[o]) {
                        if ("que" in Site[o]) {
                            que.push(Site[o]);
                        } else {
                            Site[o][f](data);
                        }
                    }
                }
            }
            for (var qued in que) {
                if (typeof que[qued][f] === 'function')
                    que[qued][f](data);
            }
        },

        init: function() {

            Site.initialized = true;

            Site.callAll("init");
            Site.onLoad();
            //$('[data-animation]').css('opacity', 0);
            $window.on("resize", Site.onResize);

            //console.log(window.location.pathname);
            // if ($("[data-url='" + window.location.pathname + "']").length) {
            //     var section = $("[data-url='" + window.location.pathname + "']");
            //     //console.log(section.attr('data-url'));
            //     Site.showSection(section, 1);

            // } else {
            //     var section = $("[data-url='/']");
            //     //console.log(section.attr('data-url'));
            //     Site.PushStates.changePath('/');
            //     Site.showSection(section, 2);
            // }


           // $('body').append('<div class="popup"><div class="popup__center"><div class="popup__center__vertical"><p><span class="title">Uwaga</span><span class="text">Informuję, że firma Landi Renzo Polska w dniach 14.08.-18.08.2017 jest zamknięta. Prosimy o uwzględnienie tego przy planowaniu zamówień.</span></p></div><div class="popup__closer"></div></div></div>');


            //console.log($('.popup').length );
           if( $('.popup').length > 0){
                var now = new Date();
                var fullDaysSinceEpoch = Math.floor(now/8.64e7);
                var popupLS = sessionStorage.getItem("popup-"+fullDaysSinceEpoch);

               //console.log(fullDaysSinceEpoch, popupLS);
                if(popupLS != 'OK'){
                    Site.popupShow();
                }
           }


            Site.onResize();
            Site.bind();

            Site.pageMain();
            Site.pageKalkulator();
            Site.pageGaleria();
            Site.pageWarsztatPojedynczy();


            setTimeout(function() {

                if ( $('.cookies__box').length > 0 ) {
                    var cookies =  Site.getCookie('cookies');

                    // console.log(newsletter);
                    if (cookies === '') {
                        Site.cookiesShow($('.cookies__box'));
                    }
                }
            }, 0);

        },





        onLoad: function() {
            Site.callAll("onLoadFirst");
            Site.callAll("onLoad");
            Site.callAll("onLoadLast");
            Site.ready();
            //$window.on('scroll', Site.onScroll);
            // Site.onScroll();
        },


        onUnLoad: function() {
            Site.callAll("onUnLoad");
        },


        // global resize handler
        onResize: function() {
            Site.w = window.innerWidth;
            Site.h = window.innerHeight;


            Site.breakpoint = Site.getBreakpoint();
            if (Site.breakpointLast != Site.breakpoint.value) {
                Site.breakpointLast = Site.breakpoint.value;
            }
            Site.callAll("onResize");
            Site.pageSwiatecznaPromocja();
            Site.hideMenuFast();


            if ($(".container_map").length > 0) {
                $(".container_map").css('width', $(window).width());
            }

            // if (Site.requestId) {
            //     window.cancelAnimationFrame(Site.requestId);
            //     Site.requestId = undefined;
            // }
            // Site.draw();

            if (Site.breakpoint.value == 'desktop') {
                $('nav').removeAttr('style');
            } else if (Site.breakpoint.value == 'tablet') {

            } else if (Site.breakpoint.value == 'phone') {

            }
        },

        bind: function() {

           $('.cookies__box__closer').on('click', function(e) {
               Site.cookiesHide(e)
           });


            $('.popup__closer').on("click", function(e) {
                Site.popupHide();
            });


            $('.hamburger').on("click", function(e) {
                Site.toggleHamburger();
            });

            $('nav > ul > li').on("click", function(e) {
                if (!Site.menuAnimating) {

                    var li = $(e.currentTarget);
                    if (li.hasClass('active')) {
                        Site.navItemHide(li, false);
                    } else {
                        Site.navItemShow(li, false);
                        li.siblings().each(function(index, el) {
                            Site.navItemHide($(el), false);
                        });
                    }
                }
            });

            $('nav > ul > li > ul > li').on("click", function(e) {

                //e.preventDefault();
                e.stopPropagation();

                if (!Site.menuAnimating) {
                    var li = $(e.currentTarget);
                    if (li.hasClass('active')) {
                        Site.navItemHide(li, false);
                        Site.navItemHide(li.closest('li'), false);
                    } else {
                        Site.navItemShow(li, false);
                        li.siblings().each(function(index, el) {
                            Site.navItemHide($(el), false);
                            Site.navItemHide($(el).closest('li'), false);
                        });
                    }
                }

            });


            $('nav > ul > li').on("mouseenter", function(e) {


                var li = $(e.currentTarget);
                // if (li.hasClass('active')) {
                //     Site.navItemHide(li, true);
                // } else {
                Site.navItemShow(li, true);
                li.siblings().each(function(index, el) {
                    Site.navItemHide($(el), true);
                });
                // }
            });

            $('nav > ul > li').on("mouseleave", function(e) {

                var li = $(e.currentTarget);
                if (li.hasClass('active')) {
                    Site.navItemHide(li, true);
                    li.removeClass('active');
                    li.find('li').removeClass('active');

                }
            });

            $('nav > ul > li > ul > li').on("mouseenter", function(e) {

                var li = $(e.currentTarget);
                if (li.hasClass('active')) {
                    Site.navItemHide(li, true);
                } else {
                    Site.navItemShow(li, true);
                    li.siblings().each(function(index, el) {
                        Site.navItemHide($(el), true);
                    });
                }
            });

            $('nav > ul > li > ul > li > ul > li').on("mouseenter", function(e) {

                var li = $(e.currentTarget);
                if (li.hasClass('active')) {
                    Site.navItemHide(li, true);
                } else {
                    Site.navItemShow(li, true);
                    li.siblings().each(function(index, el) {
                        Site.navItemHide($(el), true);
                    });
                }
            });




            // $('nav ul li ul li').on("click", function(e) {
            //     $(e.currentTarget).addClass('active');
            //     $(e.currentTarget).children('ul').css({ 'display': 'block' });
            // });


            // $('nav ul li ul li ul li').on("click", function(e) {
            //     $(e.currentTarget).addClass('active');
            //     $(e.currentTarget).children('ul').css({ 'display': 'block' });
            // });




            $(".only_number").keyup(function() {
                this.value = this.value.replace(/[^0-9\.]/g, '');
            });


            $(document).on("click", "a.close", function() {
                $('.container_box_info').html('');
            });


            $(document).on("click", ".targi-main", function() {
                //alert("Goodbye!"); 

                $(".targi-main").animate({
                    left: "-800px"
                }, 2000, function() {
                    // Animation complete.
                });
            });


            $(document).on("click", ".marker-group", function() {
                //alert("Goodbye!"); 

                //$('.container_box_info').html('');

                if (!$(this).hasClass('active')) {
                    //<div class="marker-group active" style="top:90px; left:500px;">
                    //alert("Goodbye!"); 
                    $(this).addClass('active').siblings('.marker-group').removeClass('active');
                    $('#one-group').html($(this).find('.hide').html());
                }

            });


            $(document).on('click', "[data-scroll-start]", function(e) {
                var sS = $(this).data('scroll-start'); //alert(sS);
                var sE = $('[data-scroll-end=' + sS + ']'); //alert(sE);

                if (!sE.length) {
                    return false;
                }

                var sT = sE.offset().top;
                //console.log(sE.offset().top, sE.position().top);
                TweenMax.to($('html, body'), 1, {scrollTop: sT, ease: Sine.easeOut });

            });



            $(".map__group__select").change(function() {

                $(".map__group__select option:selected").each(function() {
                    var country = $(this).val();
                    // console.log(country);

                    $('.marker-group').each(function(index, el) {
                        //console.log($(el).find('h2').text().toLowerCase());
                        if ($(el).find('h2').text().toLowerCase() == country) {
                            $(el).click();
                        }

                    });


                });

            })








            $('#szukaj').focus(function() {
                $('#szukaj').animate({
                    width: '144px'
                }, 500, function() {
                    // Animation complete.

                });
            });


            $('#szukaj').focusout(function() {
                $('#szukaj').animate({
                    width: '100px'
                }, 500, function() {
                    // Animation complete.

                });
            });


            $(".parts_content").click(function() {
                var wysokosc = $(this).parent().find('.hide').height();
                //alert(wysokosc);
                if ($(this).hasClass('active')) {
                    $(this).find('.hide').hide('slow');
                    $(this).removeClass('active');
                    // $(this).find('.arrow img').rotate({ animateTo: 0 });
                    var arrow = $(this).find('.arrow img');
                    TweenMax.to(arrow, 0.6, { rotation: 0, transformOrigin: '50% 50%' });
                } else {
                    $(this).addClass('active');
                    $(this).find('.hide').show('slow');
                    //  $(this).find('.arrow img').rotate({ animateTo: 180 });
                    var arrow = $(this).find('.arrow img');
                    TweenMax.to(arrow, 0.6, { rotation: 180, transformOrigin: '50% 50%' });
                }
            });


            $(".nr_1").click(function() { $(".part_1").click(); });
            $(".nr_2").click(function() { $(".part_2").click(); });
            $(".nr_3").click(function() { $(".part_3").click(); });
            $(".nr_4").click(function() { $(".part_4").click(); });
            $(".nr_5").click(function() { $(".part_5").click(); });
            $(".nr_6").click(function() { $(".part_6").click(); });
            $(".nr_7").click(function() { $(".part_7").click(); });
            $(".nr_8").click(function() { $(".part_8").click(); });
            $(".nr_9").click(function() { $(".part_9").click(); });
            $(".nr_10").click(function() { $(".part_10").click(); });


            //Kontakt

            $("#representative, #representative_arrow, #departments, #departments_arrow").click(function() {
                var wysokosc = $(this).parent().find('.hide').height();
                //alert(wysokosc);
                if ($(this).parent().hasClass('active')) {
                    $(this).parent().find('.hide').hide('slow');
                    $(this).parent().removeClass('active');
                    //$(this).parent().find('.arrow img').rotate({animateTo:0});
                    var arrow = $(this).find('.arrow img');
                    TweenMax.to(arrow, 0.6, { rotation: 0, transformOrigin: '50% 50%' });
                } else {
                    $(this).parent().addClass('active');
                    $(this).parent().find('.hide').show('slow');
                    //$(this).parent().find('.arrow img').rotate({animateTo:180});
                    var arrow = $(this).find('.arrow img');
                    TweenMax.to(arrow, 0.6, { rotation: 180, transformOrigin: '50% 50%' });
                }
            });
        },

        cookiesShow: function(cookiesBox){
           // console.log('cookies show');
            cookiesBox.addClass('showed');
            TweenMax.set(cookiesBox, {'display': 'block', y: '100%'});
            TweenMax.to( cookiesBox, 0, { y: '0%',  ease:  Sine.easeOut,  onComplete: function() {  }} );
        },

        cookiesHide: function(e){
            var cookiesBox = $(e.currentTarget).closest('.cookies__box');
            TweenMax.to( cookiesBox, 0.5, { y: '100%', ease:  Sine.easeOut, onComplete: function() {
                cookiesBox.css({'display': 'none'});
                cookiesBox.removeClass('showed');


            }} );
            Site.setCookie('cookies', 'close', 365);
        },


        setCookie: function(cname, cvalue, exdays){
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = 'expires=' + d.toUTCString();
            document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
        },


        getCookie: function(cname){
            var name = cname + '=';
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return '';
        },



        popupShow: function() {
            $('.popup').css({'display':'block'});
            TweenMax.to( $('.popup'), 0.6, { opacity: 1});
        },

        popupHide: function() {

            TweenMax.to( $('.popup'), 0.6, { opacity: 0, onComplete: function() {$('.popup').css({'display':'none'})} });

            var now = new Date();
            var fullDaysSinceEpoch = Math.floor(now/8.64e7);
            sessionStorage.setItem("popup-"+fullDaysSinceEpoch, "OK");

        },

        navItemShow: function(li, fast) {
            li.addClass('active');
            var ul = li.children('ul');
            ul.css({ 'display': 'block' });
            var h = ul.height();
            // console.log(h, fast);
            Site.menuAnimating = true;
            TweenMax.fromTo(ul, 0, { 'height': '0px' }, { 'height': h, ease: Power2.easeOut, onComplete: function() { Site.menuAnimating = false; } });
        },


        navItemHide: function(li, fast) {
            var ul = li.children('ul');
            var h = ul.height();
            // console.log(h, fast);
            Site.menuAnimating = true;
            TweenMax.fromTo(ul, 0, { 'height': h }, {
                'height': 0,
                ease: Power2.easeOut,
                onComplete: function() {
                    li.removeClass('active');
                    ul.removeAttr('style');
                    Site.menuAnimating = false;
                }
            });
        },

        toggleHamburger: function() {

            if ($('.hamburger').hasClass('active')) {
                this.hideMenu();
            } else {
                this.showMenu();
            }

        },
        hideMenuFast: function() {
            if (Site.breakpoint.value != 'desktop') {
                $('.hamburger').removeClass('active');
                $('html').removeClass('show__menu');
                $('nav').css({ 'display': 'absolute' });
                TweenMax.set($('nav'), { x: '100%' });
                TweenMax.set($('.bar1'), { rotation: 0, y: 0, transformOrigin: '50% 50%' });
                TweenMax.set($('.bar2'), { rotation: 0, y: 0, transformOrigin: '50% 50%' });

            } else {
                TweenMax.set($('nav'), { x: '0%' });
            }
        },
        hideMenu: function() {
            $('nav').css({ 'display': 'absolute' });
            TweenMax.to($('nav'), 0.6, {
                x: '100%',
                ease: Power2.easeOut,
                onComplete: function() {
                    $('.hamburger').removeClass('active');
                    $('html').removeClass('show__menu');

                    $('nav ul li').each(function(index, el) {
                        Site.navItemHide($(el), true);
                    });
                }
            });
            TweenMax.to($('.bar1'), 0.6, { rotation: 0, y: 0, transformOrigin: '50% 50%' });
            TweenMax.to($('.bar2'), 0.6, { rotation: 0, y: 0, transformOrigin: '50% 50%' });
            TweenMax.set($('.container_content, footer'), { 'display': 'block' });






        },
        showMenu: function() {





            TweenMax.set($('nav'), { 'position': 'fixed' });

            TweenMax.to($('nav'), 0.6, {
                x: '0%',
                ease: Power2.easeOut,
                onComplete: function() {
                    $('.hamburger').addClass('active');
                    $('html').addClass('show__menu');
                    $('nav').css({ 'display': 'relative' });

                    //  TweenMax.set($('html, body'), { scrollTop: 0 });


                    TweenMax.set($('nav'), { 'position': 'absolute' });
                    TweenMax.set($('.container_content, footer'), { 'display': 'none' });
                }
            });
            TweenMax.to($('.bar1'), 0.6, { rotation: 45, y: 4, transformOrigin: '50% 50%' });
            TweenMax.to($('.bar2'), 0.6, { rotation: -45, y: -4, transformOrigin: '50% 50%' });
        },



        pageMain: function() {

            if ($('body').hasClass('main')) {

                if (Site.requestIdDraw1) {
                    window.cancelAnimationFrame(Site.requestIdDraw1);
                    Site.requestIdDraw1 = undefined;
                }

                if (Site.requestIdDraw2) {
                    window.cancelAnimationFrame(Site.requestIdDraw2);
                    Site.requestIdDraw2 = undefined;
                }
                Site.slideCar();


                $(".aplikacja-main").animate({
                    right: "0"
                }, 1500, function() {
                    // Animation complete.
                });


                // setTimeout(function() {
                //     $(".promocja-main").animate({
                //         left: "0"
                //     }, 1500, function() {
                //         // Animation complete.
                //     });
                // }, 1000);

                // setTimeout(function() {
                //     $(".promocja-shell").animate({
                //         left: "0"
                //     }, 1500, function() {
                //         // Animation complete.
                //     });
                // }, 2000);


                setTimeout(function() {
                    $(".pakiet-powitalny-shell").animate({
                        left: "0"
                    }, 1500, function() {
                        // Animation complete.
                    });
                }, 2000);



                $(".targi-main").animate({
                    left: "0"
                }, 2000, function() {
                    // Animation complete.
                });


            }
        },

        pageKalkulator: function() {


            if ($("#gazeo_symulator2").length > 0) {

                ifrm2 = document.createElement("IFRAME");
                ifrm2.setAttribute("src", "http://gazeo.pl/symulator_lr/kalkulator_d.html");
                ifrm2.setAttribute("borderFrame", "0");
                ifrm2.style.width = "994px";
                ifrm2.style.height = "1514px";
                ifrm2.style.border = "0px";
                document.getElementById("gazeo_symulator2").appendChild(ifrm2);


                ifrm = document.createElement("IFRAME");
                ifrm.setAttribute("src", "http://gazeo.pl/symulator_lr/kalkulator.html");
                ifrm.setAttribute("borderFrame", "0");
                ifrm.style.width = "994px";
                ifrm.style.height = "1524px";
                ifrm.style.border = "0px";
                document.getElementById("gazeo_symulator").appendChild(ifrm);

                //$(".part_1").click(); 
                //  $(".part_2").click();

            }

        },


        pageSwiatecznaPromocja: function() {
            if ($("#car-snow").length > 0 && Site.breakpoint.value == 'desktop') {

                clearTimeout(Site.requestIdDrawSnowCar2);
                window.cancelAnimationFrame(Site.requestIdDrawSnowCar2);
                Site.requestIdDrawSnowCar2 = undefined;

                if ($(".container_map").length > 0) {
                    $(".container_map").css('width', $(window).width());
                }


                if ($("#car-snow").length > 0) {

                    var car = $("#car-snow");
                    var tree = $("#tree-snow");
                    var wheels = $(".car-snow-wheel");
                    var start = (($(window).width() - 960) / 2) + 500;
                    var end = $(window).width() / 2;
                    var stop = -(($(window).width() - 960) / 2) - 1000;
                    //var way =$(window).width() // 1440;

                    //car.css({'transform':'translateX('+start+'px)'}); 
                    //console.log(start);


                    function Drive() {
                        car.css({ 'transform': 'translateX(' + start + 'px)' });
                        car.css({ "border-spacing": start });
                        car.stop().animate({ borderSpacing: 0 }, {
                            step: function(now, fx) {
                                now = Math.round(now);
                                //  console.log(now);

                                $(this).css({ 'transform': 'translateX(' + now + 'px)' });
                                //    if(now % 6 == 0){
                                //       deg = Math.sin(now/2) * 5;
                                //       console.log(now+ ' '+ deg);
                                //       tree.css({'transform':'rotate('+deg+'2deg)'}); 
                                // //   }

                                wheels.css({ 'transform': 'rotate(' + now + '2deg)' });
                            },
                            duration: 2000,
                            complete: function() {
                                clearTimeout(Site.requestIdDrawSnowCar1);
                                window.cancelAnimationFrame(Site.requestIdDrawSnowCar1);
                                Site.requestIdDrawSnowCar1 = undefined;

                                Site.requestIdDrawSnowCar1 = setTimeout(function() {
                                    Drive2();
                                }, 4000);

                            }
                        }, 'linear');
                    }


                    function Drive2() {

                        car.css({ "border-spacing": 0 });
                        car.stop().animate({ borderSpacing: stop }, {
                            step: function(now, fx) {
                                now = Math.round(now);
                                //console.log(now);
                                $(this).css({ 'transform': 'translateX(' + now + 'px)' });
                                // tree.css({'transform':'rotate('+now+'2deg)'}); 
                                wheels.css({ 'transform': 'rotate(' + now + '2deg)' });
                            },
                            duration: 5000,
                            complete: function() {
                                Drive();
                            }
                        }, 'linear');
                    }





                    Drive();


                    var c = document.getElementById('canv'),
                        contextMy = c.getContext("2d");
                    var w = c.width = window.innerWidth,
                        h = c.height = 640; //window.innerHeight;



                    Snowy();

                    function Snowy() {
                        var snow, arr = [];
                        var num = 500,
                            tsc = 1,
                            sp = 1;
                        var sc = 1.3,
                            t = 0,
                            mv = 20,
                            min = 1;
                        for (var i = 0; i < num; ++i) {
                            snow = new Flake();
                            snow.y = Math.random() * (h + 50);
                            snow.x = Math.random() * w;
                            snow.t = Math.random() * (Math.PI * 2);
                            snow.sz = (100 / (10 + (Math.random() * 100))) * sc;
                            snow.sp = (Math.pow(snow.sz * .8, 2) * .15) * sp;
                            snow.sp = snow.sp < min ? min : snow.sp;
                            arr.push(snow);
                        }
                        go();

                        function go() {


                            Site.requestIdDrawSnowCar2 = window.requestAnimationFrame(go);
                            contextMy.clearRect(0, 0, w, h);
                            contextMy.fillStyle = 'hsla(242, 95%, 3%, 0)';
                            contextMy.fillRect(0, 0, w, h);
                            contextMy.fill();
                            for (var i = 0; i < arr.length; ++i) {
                                f = arr[i];
                                //  f.t += .05;
                                // f.t = f.t >= Math.PI * 2 ? 0 : f.t;
                                f.y += f.sp;
                                // f.x += Math.sin(f.t * tsc) * (f.sz * .3);
                                if (f.y > h + 50) f.y = -10 - Math.random() * mv;
                                // if (f.x > w + mv) f.x = - mv;
                                // if (f.x < - mv) f.x = w + mv;
                                f.draw();
                            }
                        }

                        function Flake() {
                            this.draw = function() {
                                this.g = contextMy.createRadialGradient(this.x, this.y, 0, this.x, this.y, this.sz);
                                this.g.addColorStop(0, 'hsla(255,255%,255%,1)');
                                this.g.addColorStop(1, 'hsla(255,255%,255%,0)');
                                contextMy.moveTo(this.x, this.y);
                                contextMy.fillStyle = this.g;
                                contextMy.beginPath();
                                contextMy.arc(this.x, this.y, this.sz, 0, Math.PI * 2, true);
                                contextMy.fill();
                            }
                        }
                    }

                }

            }
        },


        pageWarsztatPojedynczy: function() {

            if ($("body.warszatPojedynczy").length > 0) {
                setTimeout(function() {
                    $('#pokaz_wszystkie').click();
                    new google.maps.event.trigger(markers[0], 'click');
                }, 1000);
            }
        },
        pageGaleria: function() {



            $("a[rel^='prettyPhoto']").prettyPhoto({
                animation_speed: 'fast',
                /* fast/slow/normal */
                slideshow: 5000,
                /* false OR interval time in ms */
                autoplay_slideshow: false,
                /* true/false */
                opacity: 0.80,
                /* Value between 0 and 1 */
                show_title: false,
                /* true/false */
                allow_resize: true,
                /* Resize the photos bigger than viewport. true/false */
                default_width: 500,
                default_height: 344,
                counter_separator_label: '/',
                /* The separator for the gallery counter 1 "of" 2 */
                theme: 'pp_default',
                /* light_rounded / dark_rounded / light_square / dark_square / facebook */
                horizontal_padding: 20,
                /* The padding on each side of the picture */
                hideflash: false,
                /* Hides all the flash object on a page, set to TRUE if flash appears over prettyPhoto */
                wmode: 'opaque',
                /* Set the flash wmode attribute */
                autoplay: false,
                /* Automatically start videos: True/False */
                modal: false,
                /* If set to true, only the close button will close the window */
                deeplinking: false,
                /* Allow prettyPhoto to update the url to enable deeplinking. */
                overlay_gallery: false,
                /* If set to true, a gallery will overlay the fullscreen image on mouse over */
                keyboard_shortcuts: true,
                /* Set to false if you open forms inside prettyPhoto */
                changepicturecallback: function() {},
                /* Called everytime an item is shown/changed */
                callback: function() {},
                /* Called when prettyPhoto is closed */
                ie6_fallback: true,
                social_tools: ''
            });


        },



        draw: function() {

            //            Site.render();

            Site.requestId = requestAnimationFrame(Site.draw);

        },



        draw1: function(id, start_x, start_y, counter, pion, poziom, height) {
            //console.log('draw1');
            //alert(id+', '+start_x+', '+start_y+', '+counter+', '+pion+', '+poziom);
            if (counter <= height) {
                var canvas = document.getElementById("canvas_" + id);
                var context = canvas.getContext("2d");
                context.strokeStyle = '#c3c3c3';
                context.beginPath();
                context.lineWidth = 1;
                context.clearRect(0, 0, 300, 300);
                context.moveTo(start_x, start_y);
                context.lineTo(start_x, start_y - pion);
                context.stroke();
                pion++;
                counter++;
            } else if (counter > height && counter <= 340) {
                var canvas = document.getElementById("canvas_" + id);
                var context = canvas.getContext("2d");
                context.strokeStyle = '#c3c3c3';
                context.beginPath();
                context.lineWidth = 1;
                context.lineJoin = 'mitter';
                context.clearRect(0, 0, 300, 300);
                context.moveTo(start_x, start_y);
                context.lineTo(start_x, start_y - pion);
                context.lineTo(start_x + poziom, start_y - pion);
                context.stroke();
                poziom++;
                counter++;
            }

            if (counter < 340) {
                setTimeout(function() { Site.draw1(id, start_x, start_y, counter, pion, poziom, height); }, 1);
            } else {
                $('#car_p_' + id).animate({
                    opacity: 1.0
                }, 1000, function() {
                    // Animation complete.
                });
            }

        },



        draw2: function(id, start_x, start_y, counter, pion, poziom, height) {
            //alert(id+', '+start_x+', '+start_y+', '+counter+', '+pion+', '+poziom);
            if (counter <= 80) {
                var canvas = document.getElementById("canvas_" + id);
                var context = canvas.getContext("2d");
                context.strokeStyle = '#c3c3c3';
                context.beginPath();
                context.lineWidth = 1;
                context.clearRect(0, 0, 300, 300);
                context.moveTo(start_x, start_y);
                context.lineTo(start_x, start_y - pion);
                context.stroke();
                pion++;
                counter++;
            } else if (counter > 80 && counter <= 340) {
                var canvas = document.getElementById("canvas_" + id);
                var context = canvas.getContext("2d");
                context.strokeStyle = '#c3c3c3';
                context.beginPath();
                context.lineWidth = 1;
                context.lineJoin = 'mitter';
                context.clearRect(0, 0, 300, 300);
                context.moveTo(start_x, start_y);
                context.lineTo(start_x, start_y - pion);
                context.lineTo(start_x - poziom, start_y - pion);
                context.stroke();
                poziom++;
                counter++;
            }

            if (counter < 340) {
                setTimeout(function() { Site.draw2(id, start_x, start_y, counter, pion, poziom, height); }, 1);
            } else {
                $('#car_p_' + id).animate({
                    opacity: 1.0
                }, 1000, function() {
                    // Animation complete.
                });
            }
        },

        slideCar: function() {


            if ($('#car_landirenzo').hasClass('active')) {

                $('#car_landirenzo').animate({
                    opacity: 0.0
                }, 1000, 'linear', function() {

                    $('#car_lovato').animate({ opacity: '1.0' }, 1000, 'linear', function() {

                        var canvas_4 = document.getElementById("canvas_4");
                        var context_4 = canvas_4.getContext("2d");
                        context_4.beginPath();
                        context_4.clearRect(0, 0, 300, 300);
                        var canvas_5 = document.getElementById("canvas_5");
                        var context_5 = canvas_5.getContext("2d");
                        context_5.beginPath();
                        context_5.clearRect(0, 0, 300, 300);
                        var canvas_6 = document.getElementById("canvas_6");
                        var context_6 = canvas_6.getContext("2d");
                        context_6.beginPath();
                        context_6.clearRect(0, 0, 300, 300);


                        if (Site.breakpoint.value == 'desktop') {
                            $('#car_info_4').animate({ top: '40px', opacity: 1.0 }, 1000, function() { Site.draw2("4", 249, 162, 0, 0, 0, 80); });
                            $('#car_plus_shadow_4').animate({ opacity: 1.0 }, 1000, function() {});
                            $('#car_info_5').delay(3000).animate({ top: '20px', opacity: 1.0 }, 1000, function() { Site.draw1("5", 51, 162, 0, 0, 0, 100); });
                            $('#car_plus_shadow_5').delay(3000).animate({ opacity: 1.0 }, 1000, function() {});
                            $('#car_info_6').delay(6000).animate({ top: '60px', opacity: 1.0 }, 1000, function() { Site.draw1("6", 51, 162, 0, 0, 0, 100); });
                            $('#car_plus_shadow_6').delay(6000).animate({ opacity: 1.0 }, 1000, function() {});
                        } else {
                            $('#car_info_4').animate({ top: '0px', opacity: 1.0 }, 1000, function() { Site.draw2("4", 249, 162, 0, 0, 0, 80); });
                            // $('#car_plus_shadow_4').animate({ opacity: 1.0 }, 1000, function() {});
                        }

                    });

                    $('#car_lovato').addClass('active');
                    $('#car_landirenzo').removeClass('active');
                    $('#car_lovato').animate({ opacity: '1.0' }, 15000, 'linear', function() { Site.slideCar(); });

                });

                $('#car_info_1').animate({ top: '20px', opacity: 0.0 }, 1000, function() {

                });
                if (Site.breakpoint.value == 'desktop') {
                    $('#car_info_2').animate({ top: '0px', opacity: 0.0 }, 1000, function() {

                    });
                    $('#car_info_3').animate({ top: '40px', opacity: 0.0 }, 1000, function() {

                    });
                }
                $('#car_plus_shadow_1, #car_plus_shadow_2, #car_plus_shadow_3').animate({ opacity: 0.0 }, 1000, function() {});
                $('#car_p_1, #car_p_2, #car_p_3').animate({ opacity: 0.0 }, 1000, function() {});



            } else {
                $('#car_lovato').animate({
                    opacity: '0.0'
                }, 1000, 'linear', function() {

                    $('#car_landirenzo').animate({ opacity: '1.0' }, 1000, 'linear', function() {

                        var canvas_1 = document.getElementById("canvas_1");
                        var context_1 = canvas_1.getContext("2d");
                        context_1.beginPath();
                        context_1.clearRect(0, 0, 300, 300);
                        var canvas_2 = document.getElementById("canvas_2");
                        var context_2 = canvas_2.getContext("2d");
                        context_2.beginPath();
                        context_2.clearRect(0, 0, 300, 300);
                        var canvas_3 = document.getElementById("canvas_3");
                        var context_3 = canvas_3.getContext("2d");
                        context_3.beginPath();
                        context_3.clearRect(0, 0, 300, 300);




                        if (Site.breakpoint.value == 'desktop') {

                            $('#car_info_1').animate({ top: '40px', opacity: 1.0 }, 1000, function() { Site.draw2("1", 249, 162, 0, 0, 0, 80); });
                            $('#car_plus_shadow_1').animate({ opacity: 1.0 }, 1000, function() {});

                            $('#car_info_2').delay(3000).animate({ top: '20px', opacity: 1.0 }, 1000, function() { Site.draw1("2", 51, 162, 0, 0, 0, 100); });
                            $('#car_plus_shadow_2').delay(3000).animate({ opacity: 1.0 }, 1000, function() {});

                            $('#car_info_3').delay(6000).animate({ top: '60px', opacity: 1.0 }, 1000, function() { Site.draw1("3", 51, 162, 0, 0, 0, 100); });
                            $('#car_plus_shadow_3').delay(6000).animate({ opacity: 1.0 }, 1000, function() {});
                        } else {
                            $('#car_info_1').animate({ top: '0px', opacity: 1.0 }, 1000, function() { Site.draw2("1", 249, 162, 0, 0, 0, 80); });
                            //$('#car_plus_shadow_1').animate({ opacity: 1.0 }, 1000, function() {});
                        }

                    });


                    $('#car_landirenzo').addClass('active');
                    $('#car_lovato').removeClass('active');
                    $('#car_landirenzo').animate({ opacity: '1.0' }, 15000, 'linear', function() { Site.slideCar(); });

                });

                $('#car_info_4').animate({ top: '20px', opacity: 0.0 }, 1000, function() {

                });
                if (Site.breakpoint.value == 'desktop') {
                    $('#car_info_5').animate({ top: '0px', opacity: 0.0 }, 1000, function() {

                    });
                    $('#car_info_6').animate({ top: '40px', opacity: 0.0 }, 1000, function() {

                    });
                }
                $('#car_plus_shadow_4, #car_plus_shadow_5, #car_plus_shadow_6').animate({ opacity: 0.0 }, 1000, function() {});
                $('#car_p_4, #car_p_5, #car_p_6').animate({ opacity: 0.0 }, 1000, function() {});


            }
        },





        ready: function() {
            var ready = true;
            for (var o in Site) {
                if (typeof(Site[o]) === "object" && Site[o] !== null) {
                    if ("loaded" in Site[o]) {
                        if (Site[o].loaded === false) {
                            ready = false;
                            return false;
                        }
                    }
                }
            }

            if (ready === true) {
                Site.isReady = true;
                $body.removeClass('is-not-ready');
                return true;
            }
        },


        iOSversion: function() {
            if (/iP(hone|od|ad)/.test(navigator.platform)) {
                var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
            }
        },
        // store current css breakpoint
        getBreakpoint: function() {

            var breakpoint = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/([\"\'])/g, ''),
                columns = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content').replace(/([\"\'])/g, '');
            $('html').removeClass('breakpoint-desktop breakpoint-tablet breakpoint-phone').addClass('breakpoint-' + breakpoint);
            return {
                columns: parseInt(columns, 10),
                desktop: (breakpoint === 'desktop'),
                tablet: (breakpoint === 'tablet'),
                phone: (breakpoint === 'phone'),
                value: breakpoint
            };
        },

        // onScroll: function() {
        //     Site.st = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        //     Site.mouseY = Site.st * 0.6 + 0.1;
        //     if (Site.lastSt && Site.lastSt == Site.st) return;
        //     Site.lastSt = Site.st;


        //     var t = 0.6;
        //     var ease = Power2.easeOut;
        //     if (Site.isShowing || Site.mouseY == 0.1) {
        //         t = 2;
        //         ease = Sine.easeOut;
        //     }

        //     TweenMax.killTweensOf(Site.groupTrianglePositionY);
        //     TweenMax.to(Site.groupTrianglePositionY, t, {
        //         var: Site.mouseY,
        //         ease: ease,
        //         delay: 0
        //     });


        //     $('[data-animation]:not(.animated)').each(function(i, el) {
        //         $el = $(el);

        //         start = $el.data('start');
        //         if (!start) start = 0.1;
        //         y = $el.offset().top;
        //         var yBottom = (Site.st + (1 - start) * Site.h);
        //         var yTop = (Site.st);

        //         if (y <= yBottom) {
        //             $el.addClass('animated');
        //             var quick = false;
        //             if (yTop >= y + $el.height()) quick = true;
        //             Site.scrollAnimation($el, $el.data('animation'), $el.data('delay'), quick);
        //         }
        //     });

        // },

        render: function() {



        },

        resizeWebGL: function() {

            Site.windowHalfX = window.innerWidth / 2;
            Site.windowHalfY = window.innerHeight / 2;

            Site.camera.aspect = window.innerWidth / window.innerHeight;
            Site.camera.updateProjectionMatrix();
            Site.renderer.setSize(window.innerWidth, window.innerHeight);

        },


    };

    $.extend(Site, SiteMethods);

}(jQuery));


(function($) {


    $window = $(window);
    $body = $("body");
    //   Site.breakpoint = Site.getBreakpoint();

    Site.isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    Site.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    Site.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !!window.chrome;
    Site.isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
    Site.isIOS = navigator.platform.match(/(iPhone|iPod|iPad)/i) ? true : false;
    Site.isIE = !!$.browser.msie;
    Site.isEdge = !!$.browser.msedge;

    var versionInt = $.browser.versionNumber;
    var isOld = false;



    if (Site.isIE && versionInt < 11)
        isOld = true;

    if (Site.isEdge && versionInt < 14)
        isOld = true;

    if (Site.isChrome && versionInt < 42)
        isOld = true;

    if (Site.isSafari && versionInt < 9)
        isOld = true;

    if (Site.isFirefox && versionInt < 47)
        isOld = true;


    FastClick.attach(document.body);

    Site.init();


    // Site.isOld = isOld;
    // if (!isOld) {
    //     Site.init();
    // } else {
    //     window.location.href = 'http://' + Site.hostname + '/browsehappy.html';
    // }


}(jQuery));
