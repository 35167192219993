/* This file requires site.js to work */


//var Site = Site || {};


/*
 * Atrybut data-api odapla odpowiednie funkcje z Site.API, przekazując dane w nim zawarte funckji.
 * Można dodać dowolną liczbę zmiennych. Dla widoku potrzebne są jedynie: action, callback, target [opcjonalny] i beforeCall [opcjonalny].
 * Zmienna 'lang' przekazywana jest automatycznie i zawiera atrybut 'lang' z html.
 * beforeCall 'prompt' odpala zawartość szablonu 'template-prompt', gdzie tekst musi być ustawiany back-endowo
 *
 */

/*
(function($) {

    Site.Api = {


        settings: {
            url: '/en/api'
        },


        init: function() {
            Site.Api.settings.url = '/' + Site.lang + '/api';
        },


        call: function(data, callback, optional) {

            var actionSlug = data.action;
            delete data.action;

            $.ajax({
                url: Site.Api.settings.url+'/'+actionSlug,
                global: false,
                type: 'POST',
                dataType: 'json',
                async: true,
                data: data
            }).always( function(response) {
                if (typeof callback === 'function')
                    callback(response, optional);

            }).fail( function(e) {
                // alert('Server Error. Please try again' + data);
                console.log('%c API error: '+ e.statusText +' ', 'background: yellow');

            });

        }

    };

})(jQuery);
*/
