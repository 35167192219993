/*
var Site = Site || {},
    $body = $body || $("body");

(function($) {

    // Handles HTML5 History

    Site.PushStates = {

        scrollChange: false, // pushState triggered by scrolling --> do not load data
        noChange: false, // pushState just for changing the page url --> do not load data
        noscroll: false, // do not scroll the page is this is set
        //oldState: null,         // preserve the previous state
        // currentPath: null,
        // blocked: false,
        contentContainer: '#content',
        loadedData: null,
        title: '',
        xhr: null,



        init: function() {

            if (!Modernizr.history) return;
            History.Adapter.bind(window, 'statechange', Site.PushStates.onState);

        },



        onLoadFirst: function() {
            Site.PushStates.bind();
        },



        onLoad: function() {
            Site.PushStates.setActiveLinks();
        },



        bind: function(target) {

            if (!Modernizr.history)
                return;

            target = target || 'body';


            $(target).find('a')
                .not("[data-history='false']")
                .not("[href^='#']")
                .not("[href$='.jpg']")
                .not("[target='_blank']")
                .not("[href^='mailto:']")
                .not("[href^='https:']")

            .off(".history").on("click.history", function(a) {


                //console.log('---------push state-----------');
                //console.log(a.currentTarget);

                a.preventDefault();
                if (Site.PushStates.noChange) return;
               // console.log('%c click link', 'background: #CEECF5;');


                // if(Site.PushStates.blocked){
                //      console.log('%c ----------------blocked', 'background: #FA5858;');
                //      return false;
                // }

                var self = $(a.currentTarget);
                var state = self.attr("href").replace("http://" + window.location.host, "");



                if (self.attr('data-history') === "replace")
                    window.History.replaceState(null, null, state);
                else
                    window.History.pushState(null, null, state);

            });


            //language change
            $(target)
                .find("a[data-history='false']")

            .off(".history").on("click.history", function(a) {

                a.preventDefault();
                var self = $(a.currentTarget);
                //pobieram link i usuwam param langset
                var state = self.attr("href").replace("?langset=true", "");
                //pobieram caly adres ($_GET) i sprawdzam czy w aktualnym adresie jest langset i ew usuwam
                var phpURL = window.location.search.replace("?langset=true", "").replace("&langset=true", "");

                //dopisuje langset do istniejacego adresu
                phpURL = (!phpURL) ? '?langset=true' : phpURL + '&langset=true';

                window.location = state + phpURL;
            });



            $(target).find('a[href^="http://"]').not('[href^="http://' + window.location.host + '"]').off('.history');
        },



        onDataLoad: function(data) {
            Site.PushStates.loadedData = data;
            if (Site.PushStates.animationTimer === 0) Site.PushStates.render(Site.PushStates.loadedData);
        },



        render: function(loadedData) {

            // Function called on successful data load
            // Site.PushStates.blocked = true;
            // console.log('%c ----------------blocked true', 'background: #F5A9A9;');
            Site.PushStates.cleanUp();


            var contentContainer = Site.PushStates.contentContainer,
                langLink = $(loadedData).find('a.lang'),
                $loadedContent = $(loadedData).find(contentContainer),
                title = $loadedContent.attr('data-title') || '',
                code = $loadedContent.html(),
                // currentPath = $loadedContent.attr('data-url'),
                loadedClass = $loadedContent.attr('class') || '';

            // if (Site.PushStates.currentPath == currentPath) {
            // console.log('----------------TEN SAM PATH');
            // console.log('%c ----------------TEN SAM PATH ' + Site.PushStates.currentPath + '', 'background: #E9EA2F;');

            Site.onUnLoad();
            window.scrollTo(0, 0);
            Site.PushStates.setTitle(title);

            $(contentContainer)
                .html(code)
                .attr('class', '')
                .addClass(loadedClass)
                .data('title', title);


            $('html, body').stop().animate({ scrollTop: 0 }, 0);


            //podmiana linka z jezykiem
            $('a.lang').attr('href', langLink.attr('href'));

            setTimeout(function() {
                $body.removeClass('is-loading-content');
            }, 1);


            Site.onLoad();
            Site.PushStates.loadedData = null;

            Site.Scrolling.scrollToPath(false);
            // Site.PushStates.blocked = false;
            //console.log('%c ----------------blocked false', 'background: #F5A9A9;');



            // } else {
            // console.log('----------------INNY PATH: '+Site.PushStates.currentPath+ ' != '+currentPath);
            //     console.log('%c ----------------INNY PATH: ' + Site.PushStates.currentPath + ' != ' + currentPath + '', 'background: #FA5858;');
            //}

        },



        onState: function() {



            // Check if the push state wasn't blocked by other settings

            //if (Site.PushStates.noChange) {console.log('-----------------------------------onState blocked '+window.location.pathname);}


            if (Site.PushStates.noChange) return;

            // Site.PushStates.currentPath = window.location.pathname;
            //console.log('%c onState : ' + window.location.pathname + '', 'background: #E9EA2F;');



            if (Site.PushStates.scrollChange) {
                // called when setting the pushState and title on scrolling
                Site.PushStates.scrollChange = false;
                return;
            }


            var contentContainer = Site.PushStates.contentContainer;

            Site.PushStates.loadedData = null;
            Site.PushStates.loadTimeout = null;
            Site.PushStates.animationTimer = null;


            if ($("[data-url='" + window.location.pathname + "']").length) {
                var section = $("[data-url='" + window.location.pathname + "']");
                Site.showSection(section,0);
            }

        },



        back: function(url) {
            console.log('--------- PushStates back------------');
            if (history.length > 2 || document.referrer.length > 0) {
                window.History.back();
            } else if (url) {
                window.History.replaceState(null, null, url);
            } else {
                window.History.replaceState(null, null, '/');
            }
        },

        setTitle: function(title) {
            var _title = title || ($("#content").data("title") || Site.title);
            // console.log('_title: ' + _title);
            document.title = _title;
        },



        goTo: function(loc) {
            if (Modernizr.history) window.History.replaceState(null, null, loc.replace('http://' + document.location.host, ''));
            else if (loc.replace('http://' + document.location.host) !== window.location.pathname) window.location = loc;
        },



        changePath: function(loc) {
            // change the window location only, do not load anything
            Site.PushStates.noChange = true;
            Site.PushStates.goTo(loc);
            Site.PushStates.setTitle();
            Site.PushStates.noChange = false;
        },



        cleanUp: function() {
            $('.is-active').removeClass('is-active');
        },



        setActiveLinks: function() {

            var part = window.location.pathname.split("/");
            // if(part[2]=='regulamin') return false;

            Site.PushStates.cleanUp();
            //console.log('setActiveLinks: '+window.location.pathname);


            $("a[href='" + window.location.pathname + "']").addClass('is-active');
            //$("a[href='/"+part[1]+"/"+part[2]+"']").addClass('is-active');

           // $('.header nav').toggleClass('has-active', $('.header nav .is-active').length > 0);

        }

    };
}(window.jQuery || window.Zepto));
*/