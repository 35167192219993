// MAPA - KONTAKT


var directionsDisplay;
var directionsService;
var markerclusterer;
var geokoder;
var map;

var markers = new Array();
var kolo;
var dymek;
var rozmiar;
var rozmiar_cien;
var punkt_startow;;
var punkt_zaczepienia;
var ikona;
var styles = [{
    stylers: [
        { hue: "#077dba" },
        { saturation: -50 },
        { gamma: 2.0 }
    ]
}, {
    featureType: "road",
    elementType: "geometry",
    stylers: [
        { hue: "#077dba" },
        { saturation: 0 },
        { gamma: 1.0 },
        { visibility: "on" }
    ]
}, {
    featureType: "road",
    elementType: "labels",
    stylers: [
        { visibility: "on" }
    ]
}, {
    featureType: "landscape",
    elementType: "all",
    stylers: [
        { hue: "#00ddff" },
        { saturation: -90 }
    ]
}];


var clusterStyles = [{
    textColor: 'white',
    textSize: 12,
    url: 'assets/templates/img/cluster-small.png',
    height: 42,
    width: 42
}, {
    textColor: 'white',
    textSize: 14,
    url: 'assets/templates/img/cluster-medium.png',
    height: 76,
    width: 76
}, {
    textColor: 'white',
    textSize: 14,
    url: 'assets/templates/img/cluster-large.png',
    height: 76,
    width: 76
}];

var mcOptions = {
    gridSize: 50,
    styles: clusterStyles,
    maxZoom: 13
};





function mapStart() {

    if ($('.container_map').length > 0) {

        directionsService = new google.maps.DirectionsService();
        geokoder = new google.maps.Geocoder();
        kolo = new google.maps.Circle();
        dymek = new google.maps.InfoWindow(); // zmienna globalna
        rozmiar = new google.maps.Size(48, 48);
        rozmiar_cien = new google.maps.Size(59, 32);
        punkt_startowy = new google.maps.Point(0, 0);
        punkt_zaczepienia = new google.maps.Point(16, 16);
        ikona = new google.maps.MarkerImage("/assets/templates/img/marker.png", rozmiar, punkt_startowy, punkt_zaczepienia);




        google.maps.event.addDomListener(window, "resize", function() {
            var center = map.getCenter();
            google.maps.event.trigger(map, "resize");
            map.setCenter(center);
        });



        var wspolrzedne = new google.maps.LatLng(51.881365, 20.878676);
        var zoom = 6;
        if (Site.breakpoint.value == 'phone') {
            var zoom = 5;
        }


        var opcjeMapy = {
            zoom: zoom,
            minZoom: 5,
            maxZoom: 16,
            center: wspolrzedne,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        map = new google.maps.Map(document.getElementById("mapka"), opcjeMapy);
        map.setOptions({ styles: styles });




        directionsDisplay = new google.maps.DirectionsRenderer();
        directionsDisplay.setMap(map);
    }

}

//Add marker on map
function addMarker(latitude, longitude, name, person, postcode, state, city, street, phone, email, www, workingtime, other, id) {

    var opcjeMarkera = {
        position: new google.maps.LatLng(latitude, longitude),
        map: map,
        icon: ikona
    }
    var marker = new google.maps.Marker(opcjeMarkera);
    marker.txt = '<div class="box_info">' + '<p class="name">' + name + '</p>' + '<a class="close"></a>' + '<div class="clear"></div>' + '<p>' + street + '</p>' + '<p>' + city + '</p>' + '<p>' + postcode + '</p><br />' + '<p>' + person + '</p>' + '<p>' + phone + '</p>' + '<p><a href="mailto:' + email + '">' + email + '</a></p>' + '<p><a href="http://' + www + '" target="_blank">' + www + '</a></p><br />' + '<p>' + workingtime + '</p><br />' + '<p>' + other + '</p><br />'
        //+'<input name="submit" type="submit" class="pokaz_dojazd" value="Pokaż dojazd"  onclick="calcRoute('+latitude+','+longitude+');" />'
        + '</dv>';
    google.maps.event.addListener(marker, "click", function() {
        //dymek.setContent(marker.txt);
        //dymek.open(map,marker);
        $('.container_box_info').html(marker.txt);
    });
    markers.push(marker); //dodanie do listy markers aby móc potem usunąć dany marker z mapy
    return marker;
}

//Delete all markers from map
function deleteAllMarkers() {
    if (markers) {
        for (i in markers) {
            markers[i].setMap(null);
        }
    }
    markers = new Array();
}


//Delete all markers from map
function deleteMarkerClusterer() {
    if (markerclusterer) {
        markerclusterer.clearMarkers();
    }
}



//Delete all directions from map
function deleteAllDirections() {
    //directionsDisplay.setMap(null); //usuniecie wszystkich doajazdów
    //directionsDisplay = new google.maps.DirectionsRenderer();
    //directionsDisplay.setMap(map);
    directionsDisplay.setDirections({ routes: [] });
}



//Calculate directions
function calcRoute(lat, lng) {
    var start = document.getElementById("start").value;
    var end = new google.maps.LatLng(lat, lng);
    var request = {
        origin: start,
        destination: end,
        travelMode: google.maps.DirectionsTravelMode.DRIVING
    };



    directionsService.route(request, function(response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
        }
    });
}

function calculateDistances() {
    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix({
        origins: [origin1, origin2],
        destinations: [destinationA, destinationB],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false
    }, callback);
}

function callback(response, status) {
    if (status != google.maps.DistanceMatrixStatus.OK) {
        alert('Error was: ' + status);
    } else {
        var origins = response.originAddresses;
        var destinations = response.destinationAddresses;
        var outputDiv = document.getElementById('outputDiv');
        outputDiv.innerHTML = '';
        deleteOverlays();

        for (var i = 0; i < origins.length; i++) {
            var results = response.rows[i].elements;
            addMarker(origins[i], false);
            for (var j = 0; j < results.length; j++) {
                addMarker(destinations[j], true);
                outputDiv.innerHTML += origins[i] + ' to ' + destinations[j] + ': ' + results[j].distance.text + ' in ' + results[j].duration.text + '<br>';
            }
        }
    }
}

function getDistance(p1, p2) {
    rad = function(x) {
        return x * Math.PI / 180;
    }

    var R = 6371; // earth's mean radius in km
    var dLat = rad(p2.lat() - p1.lat());
    var dLong = rad(p2.lng() - p1.lng());

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    return d.toFixed(3);
}


//Add markers to cirle
function addMarkerToCircle(p1, p2, name, person, postcode, state, city, street, phone, email, workingtime, other, id) {
    rad = function(x) {
        return x * Math.PI / 180;
    }

    var R = 6371; // earth's mean radius in km
    var dLat = rad(p2.lat() - p1.lat());
    var dLong = rad(p2.lng() - p1.lng());


    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    //alert(parseFloat(d.toFixed(3))+' < '+parseFloat($("#zakres").val()));
    if (parseFloat(d.toFixed(3)) < parseFloat($("#zakres").val())) {
        //alert('POSZŁO');
        //addMarker(p2.lat(), p2.lng(),name);
        addMarker(p2.lat(), p2.lng(), name, person, postcode, state, city, street, phone, email, workingtime, other, id);

        return true;
    } else {
        return false;
    }
}




$(document).ready(function() {



    $('#zakres').keyup(function() {
        this.value = this.value.replace(/[^0-9]/g, '');
    });

    $("#szukaj_warsztatow").click(function() {

        geokoder.geocode({ address: $("#start").val() }, obslugaGeokodowania);

        function obslugaGeokodowania(wyniki, status) {

            if (status == google.maps.GeocoderStatus.OK) {
                // tutaj instrukcje, jeśli geokodowanie się powiodło
                //alert(wyniki[0].geometry.location);
                map.setCenter(wyniki[0].geometry.location);
                deleteAllMarkers(); //Remove all markers
                deleteAllDirections(); //Remove dojazdy
                deleteMarkerClusterer();

                kolo.setMap(null); //Usuwamy poprzednie Circle
                var srodek = map.getCenter();
                var radius = $("#zakres").val() * 1000;
                kolo = new google.maps.Circle({
                    map: map,
                    center: srodek,
                    radius: radius,
                    strokeColor: '#077dba',
                    strokeWeight: 0,
                    strokeOpacity: 0.8,
                    fillColor: '#b3d5e7',
                    fillOpacity: 0.4
                });

                /*
                    var p1 = wyniki[0].geometry.location;
                    var p2 = new google.maps.LatLng(51.506283, 19.404573);
                    var odleglosc = getDistance(p1, p2);
                    alert(odleglosc);
*/


                //var p1 = wyniki[0].geometry.location;
                //var p2 = new google.maps.LatLng(51.506283, 19.404573);
                //addMarkerToCircle(p1, p2);
                $ilosc_punktow = 0;
                for (i in localizations) {
                    var p1 = wyniki[0].geometry.location;
                    var p2 = new google.maps.LatLng(localizations[i][0], localizations[i][1]);
                    if (addMarkerToCircle(p1, p2, localizations[i][2], localizations[i][3], localizations[i][4], localizations[i][5], localizations[i][6], localizations[i][7], localizations[i][8], localizations[i][9], localizations[i][10], localizations[i][11], localizations[i][12], localizations[i][13])) {
                        $ilosc_punktow++;
                    }
                }
                markerclusterer = new MarkerClusterer(map, markers, mcOptions);
                $("#komunikat").html('Znaleziono ' + $ilosc_punktow + ' warsztatów.');


            } else {
                // tutaj instrukcje, jeśli geokodowanie się nie powiodło
                //alert('bład');


                $("#komunikat").html('Nie znaleziono podanej miejscowości.');

            }
        }

    });



    $("#pokaz_wszystkie").click(function() {
        if (Site.breakpoint.value == 'phone') {
            map.setZoom(5);
        } else {
            map.setZoom(6);
        }
        map.setCenter(new google.maps.LatLng(52.068812, 19.479744));
        deleteAllMarkers(); //Remove all markers
        deleteAllDirections(); //Remove dojazdy
        deleteMarkerClusterer();
        kolo.setMap(null); //Usuwamy poprzednie Circle

        $ilosc_punktow = 0;
        for (i in localizations) {
            addMarker(localizations[i][0], localizations[i][1], localizations[i][2], localizations[i][3], localizations[i][4], localizations[i][5], localizations[i][6], localizations[i][7], localizations[i][8], localizations[i][9], localizations[i][10], localizations[i][11], localizations[i][12], localizations[i][13]);
            $ilosc_punktow++;
        }

        markerclusterer = new MarkerClusterer(map, markers, mcOptions);

        $("#komunikat").html('Znaleziono ' + $ilosc_punktow + ' warsztatów.');


    });

});



//google.maps.event.addDomListener(window, 'load', mapStart);
