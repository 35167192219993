// invoke function with specified size and floating tooltips;
$('#map-poland').cssMap({
    'size': 500, // set map size;
    'tooltips': 'visible' // floating tooltips;
});


//alert(document.URL);
//alert($(location).attr('hash'));
//$('#lodzkie').css('display','block');

var hash = $(location).attr('hash');

if (hash != "") {
    $(".hide").css('display', 'none');

    if (hash == '#dolnoslaskie') {
        $('#dolnoslaskie').css('display', 'block');
    } else if (hash == '#kujawsko-pomorskie') {
        $('#kujawsko-pomorskie').css('display', 'block');
    } else if (hash == '#lubelskie') {
        $('#lubelskie').css('display', 'block');
    } else if (hash == '#lubuskie') {
        $('#lubuskie').css('display', 'block');
    } else if (hash == '#lodzkie') {
        $('#lodzkie').css('display', 'block');
    } else if (hash == '#malopolskie') {
        $('#malopolskie').css('display', 'block');
    } else if (hash == '#mazowieckie') {
        $('#mazowieckie').css('display', 'block');
    } else if (hash == '#opolskie') {
        $('#opolskie').css('display', 'block');
    } else if (hash == '#podkarpackie') {
        $('#podkarpackie').css('display', 'block');
    } else if (hash == '#podlaskie') {
        $('#podlaskie').css('display', 'block');
    } else if (hash == '#pomorskie') {
        $('#pomorskie').css('display', 'block');
    } else if (hash == '#slaskie') {
        $('#slaskie').css('display', 'block');
    } else if (hash == '#swietokrzyskie') {
        $('#swietokrzyskie').css('display', 'block');
    } else if (hash == '#warminsko-mazurskie') {
        $('#warminsko-mazurskie').css('display', 'block');
    } else if (hash == '#wielkopolskie') {
        $('#wielkopolskie').css('display', 'block');
    } else if (hash == '#zachodniopomorskie') {
        $('#zachodniopomorskie').css('display', 'block');
    }

}


$(".poland li").click(function() {
    $(".hide").css('display', 'none');
    var hash = $(this).children('a').attr('href');

    if (hash == '#dolnoslaskie') {
        $('#dolnoslaskie').css('display', 'block');
    } else if (hash == '#kujawsko-pomorskie') {
        $('#kujawsko-pomorskie').css('display', 'block');
    } else if (hash == '#lubelskie') {
        $('#lubelskie').css('display', 'block');
    } else if (hash == '#lubuskie') {
        $('#lubuskie').css('display', 'block');
    } else if (hash == '#lodzkie') {
        $('#lodzkie').css('display', 'block');
    } else if (hash == '#malopolskie') {
        $('#malopolskie').css('display', 'block');
    } else if (hash == '#mazowieckie') {
        $('#mazowieckie').css('display', 'block');
    } else if (hash == '#opolskie') {
        $('#opolskie').css('display', 'block');
    } else if (hash == '#podkarpackie') {
        $('#podkarpackie').css('display', 'block');
    } else if (hash == '#podlaskie') {
        $('#podlaskie').css('display', 'block');
    } else if (hash == '#pomorskie') {
        $('#pomorskie').css('display', 'block');
    } else if (hash == '#slaskie') {
        $('#slaskie').css('display', 'block');
    } else if (hash == '#swietokrzyskie') {
        $('#swietokrzyskie').css('display', 'block');
    } else if (hash == '#warminsko-mazurskie') {
        $('#warminsko-mazurskie').css('display', 'block');
    } else if (hash == '#wielkopolskie') {
        $('#wielkopolskie').css('display', 'block');
    } else if (hash == '#zachodniopomorskie') {
        $('#zachodniopomorskie').css('display', 'block');
    }

});
