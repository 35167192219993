/*
var Site = Site || {},
    $window = $window || $(window),
    $body = $body || $("body");


(function($) {

 
    Site.ErrorPage = {

        container: '.container-img-map-error-page',
        $container: null,

        onLoad: function() {
        	if( !$( Site.ErrorPage.container).length ) return;
            Site.ErrorPage.$container = $(Site.ErrorPage.container);
            Site.ErrorPage.onResize();
        },

        onUnLoad: function() {
            Site.ErrorPage.$container = null;
            $('#content').css({'display':'', 'width':''});
        },

        onResize: function() {
        	if( !$( Site.ErrorPage.container).length ) return;
        	var hW = $('.header').height();
            $('#content').css({'display':'table', 'width':'100%'});

        	Site.ErrorPage.$container.css({'margin-top': hW});
        },

    };



}(jQuery));
*/
