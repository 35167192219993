

/*




// shim layer with setTimeout fallback
window.requestAnimFrame = (function() {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        false;
})();



 

var Site = Site || {};



    (function($){


 


        Site.Scrolling = {

            
            que: true,

            // vars
            el: "[data-scroll]",
            cache: null,
            headerHeight: 0,

            // states
            scrolled: false,

            loop: null,


            $header: null,
            header: '#primary-nav',


            $last_height: 0,  //llast height windows browser

            initialize: function() {

                function loop() {
                    if ( Site.Scrolling.cache ) {
                           //---------------------------------------------------------------------------------------------
                        Site.Scrolling.onScroll(); 
                        Site.Scrolling.loop = requestAnimationFrame( loop );
                    }
                }

                Site.Scrolling.$header = $(Site.Scrolling.header);


                if (requestAnimationFrame) {

                    $window.off(".scrolling").on("scroll.scrolling", Site.Scrolling.onScrollCheck).trigger('scroll.scrolling');
                    Site.Scrolling.loop = requestAnimationFrame( loop );
               
                }
                else { 
                    Site.Scrolling.isScrolling = true;
                    $window.off(".scrolling").on("scroll.scrolling", Site.Scrolling.onScroll).trigger('scroll.scrolling');
                }

                Site.Scrolling.$el = $(Site.Scrolling.el);


                if ( Site.Scrolling.$el.length ) {

                    Site.Scrolling.ready = true;
                    Site.Scrolling.saveCache();

                    $body.imagesLoaded(function() {
                        Site.Scrolling.saveCache();
                    });


                    if ( window.location.pathname.split("/").length > 2 && Site.isReady === true )
                    {
                        Site.Scrolling.scrollToPath(false);
                    }

                }


            },

            requestFrame: function() {
                Site.Scrolling.onScroll();
                requestAnimationFrame( loop );
            },

   
            onUnLoad: function() {
                Site.Scrolling.cache = [];

                if ( cancelAnimationFrame ) {
                    cancelAnimationFrame(Site.Scrolling.loop);
                }
                Site.Scrolling.loop = null;
                $window.off(".scrolling");
            },

            isScrolling: false,
            scrollTime: null,

            onScrollCheck: function() {
                Site.Scrolling.isScrolling = true;
                clearTimeout(Site.Scrolling.scrollTime);
                Site.Scrolling.scrollTime = setTimeout(function() {
                    Site.Scrolling.isScrolling = false;
                }, 250);
            },

            onScroll: function() {


                if ( Site.isReady === false ) return;
                if ( !Site.Scrolling.isScrolling ) return;
                if ( !Site.Scrolling.cache ) return;

               // if(Modernizr.touch) return;

                var sT = (window.scrollY) ? window.scrollY : $window.scrollTop(),
                    screenCenter = sT + $window.height() * 0.10, //0.33
                    headerHeight = Site.Scrolling.headerHeight,
                    inCenter = null,
                    inCenterPress = null,
                    cache,
                    l,
                    i,
                    toSnap = false,
                    snapTo = 0,
                    snapGutter = 20,
                    inView = [];

                   // console.log('sT: '+sT+' | screenCenter: '+screenCenter+' | headerHeight: '+headerHeight);



                if ( !Site.Scrolling.ready ) return;



                //alert( Site.Scrolling.$last_height+' != '+ screen.availHeight+' | '+ $window.height()+' | '+window.innerHeight+' | '+window.outerHeight+' | '+document.documentElement.clientHeight);
                if(Site.Scrolling.$last_height != window.innerHeight && Site.breakpoint.tablet){
                    //alert( 'zmieniono: '+Site.Scrolling.$last_height+' != '+ window.innerHeight + "("+$window.innerHeight()+')');
                     Site.Scrolling.$last_height=window.innerHeight;

                } 
 
                

 


                cache = Site.Scrolling.cache;
                l = cache.length;

              //  var ccc="";
               // console.log('------------------------------------------------------- sT '+sT);
                for ( i = 1; i < l; i++ ) {
                    
                   // console.log('cache '+i+' '+cache[i].path + ' '+ cache[i].top);
                    if ( cache[i].top < sT + cache[0].height && cache[i].top + cache[i].height >= sT || i==2) {


                         //ccc = ccc+i+', ';


                      //  console.log('     cache '+i+' '+cache[i].path + ' '+ cache[i].top);
                       

                        // in center
                        if ( cache[i].top < screenCenter && cache[i].top + cache[i].height > screenCenter ) {
                            inCenter = cache[i];
                        }


                   
                        //case 1 - pased the top of the window
                        if ( cache[i].top < sT ) {
                            cache[i].$el.addClass("is-past-fold");
                        }
                        else {
                            cache[i].$el.removeClass("is-past-fold");
                        }

                        //case 1 b - pased the top of the window
                        //if ( cache[i].top < sT + headerHeight ) {}

                        // case 2 - is in view
                        if ( cache[i].top < sT + cache[0].height && cache[i].top + cache[i].height >= sT ) {
                            cache[i].$el.addClass("is-in-view");

                            if ( cache[i].role in Site.Scrolling.callbacks ) {
                                if ( !Modernizr.touch ) {
                                    Site.Scrolling.callbacks[cache[i].role]( cache[i], sT );
                                }
                            }

                            inView.push(cache[i]);


                        }
                        else {
                            cache[i].$el.removeClass("is-in-view");
                        }

                        // case 2b - is aligned with window top
                        //if ( cache[i].top === sT ) {
                        //    cache[i].$el.addClass("is-aligned");
                        //}
                       // else {
                        //    cache[i].$el.removeClass("is-aligned");
                        //}
                        


                         //case 3 - bottom is visible,
                        if ( cache[i].top + cache[i].height <= cache[0].height + sT ) {
                            cache[i].$el.addClass("is-past-bottom");
                        }
                        else {
                            cache[i].$el.removeClass("is-past-bottom");
                        }


                    }  //---------------------------------------------------------------
                }

               // console.log( ccc );


                // scrolling + pushState

                if( inCenter && !Site.PushStates.noChange ){

                    if(Modernizr.history) {
                        
                        Site.PushStates.scrollChange = true;
                        
                        var obj = inCenter,
                            title = obj.title,
                            path = obj.path;


                        if (Modernizr.history)
                            window.History.replaceState(null, null, path);

                        Site.PushStates.setTitle(title);

                        
                        if(!Site.PushStates.noChange){

                            if(Site.PushStates.cleanUp)
                                Site.PushStates.cleanUp();

                            if(Site.PushStates.setActiveLinks)
                                Site.PushStates.setActiveLinks();

                        }

                        Site.PushStates.scrollChange = false;

                    }
                }


            },

            callbacks: {
                
            },



            saveCache: function() {
                
                if ( !Site.Scrolling.$el ) return;

                if ( !Site.Scrolling.ready ) return;

                var $el,
                cache = [];

                cache.push({
                    height: $window.height()
                });

                Site.Scrolling.cache = Site.Scrolling.cache || [];

                Site.Scrolling.$el.each(function(i, el){
                    $el = $(el);
                    cache.push({
                        $el: $el,
                        height: $el.outerHeight(),
                        top: $el.offset().top,
                        role: $el.data("scroll"),
                        path: $el.data("path") || false,
                        title: $el.data("title") || false,
                        callbackComplete: (Site.Scrolling.cache[i]) ? Site.Scrolling.cache[i].callbackComplete : false,
                        // playing: (Site.Scrolling.cache) ? Site.Scrolling.cache[i].playing : false,
                        // lazyDone: (Site.Scrolling.cache) ? Site.Scrolling.cache[i].lazyDone : false,
                        //$child: ($el.data("scroll") === "hero" || $el.data("scroll") === "hero_about") ? $el.find(".hero-content") : false
                    });
                    $el.data("cache", i);
                });

                Site.Scrolling.headerHeight = Site.Scrolling.$header.height();

                Site.Scrolling.cache = cache;
                //---------------------------------------------------------------------------------------------przy starcie 2 razy wywołany onscroll
                //Site.Scrolling.onScroll();
            },



            scrollToPath: function(obj){

              

               
                var targetScroll = 0,
                    scrollOffset = $('.header').height(),
                    $article = $("[data-path='" + window.location.pathname + "']"),
                    parts = window.location.pathname.split("/");

                
                //console.log($article);

                Site.PushStates.noChange = true;


                //console.log('pathname :'+window.location.pathname);


                if($("[data-path='" + window.location.pathname + "']").length) {
                    targetScroll = ($article.offset().top) - scrollOffset;

                    if($("[data-path='" + window.location.pathname + "']").data("title")) {
                        Site.PushStates.setTitle($("[data-path='" + window.location.pathname + "']").data("title"));
                    }

                }
                else if(parts.length > 2) {
                    if($("[data-path='/" + parts[1] + "']").length) {
                        $article = $("[data-path='/" + parts[1] + "']");

                        if($article.data("title")) {
                            Site.PushStates.setTitle($article.data("title"));
                        }

                        targetScroll = ($article.offset().top) - scrollOffset;
                    }
                }


                setTimeout(function() {

                    if(Site.PushStates.setActiveLinks)
                        Site.PushStates.setActiveLinks();

              

                     if(obj=="mobile_recipe"){
                         Site.NiceScroll.scroll({y:targetScroll,  speed: 0.35, ease:Circ.easeOut, callback:function(){
                            Site.PushStates.noChange = false;
                        }});    
                     }else{
                        Site.NiceScroll.scroll({y:targetScroll, callback:function(){
                            Site.PushStates.noChange = false;
                        }});
                    }

                }, 50);
      
            },


            resizeTime: null,

            onResize: function() {
                clearTimeout(Site.Scrolling.resizeTime);
                Site.Scrolling.resizeTime = setTimeout(Site.Scrolling.saveCache, 100);
            }




        };



    }(jQuery));

*/